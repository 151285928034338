import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider, CSSReset } from "@chakra-ui/core";
import { BrowserRouter as Router } from 'react-router-dom';
import {ApolloClient, createHttpLink, InMemoryCache, ApolloProvider} from '@apollo/client';
import {HOST, PROTOCOL} from './config.json';

const link = createHttpLink({
    uri: `${PROTOCOL}://${HOST}/graphql`,
    credentials: 'include'
});

const client = new ApolloClient({
    link,
    cache: new InMemoryCache()
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
        <CSSReset />
        <Router>
            <ApolloProvider client={client}>
                <App />
            </ApolloProvider>
        </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
