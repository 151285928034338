import React from "react";
import { Link } from 'react-router-dom';
import Avatar from "@chakra-ui/core/dist/Avatar";

export function MenuComponent( { guilds, bot } ) {

    return (
        <div>
        <h1>Guilds</h1>
        {guilds.included.map((guild) => (
            <div>
                <li>
                    <Avatar
                        size="sm"
                        name={guild.name}
                        src={`https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`}
                    />
                    <Link to={`/dashboard/${guild.id}`}><strong>{guild.name}</strong></Link>
                </li>
            </div>
        ))}
        {guilds.excluded.map((guild) => (
            <div>
                <li>
                    <Avatar
                        size="sm"
                        name={guild.name}
                        src={`https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`}
                    />
                    <a href={`https://discord.com/api/oauth2/authorize?client_id=${bot.id}&permissions=2113928951&scope=bot&guild_id=${guild.id}`} target="_blank" rel="noopener noreferrer"><strong>{guild.name}</strong>  Invite me!!</a>
                </li>
            </div>
        ))}
        </div>
    );
}
