import React from "react";
import { Button } from "@chakra-ui/core";
import { HOST, PROTOCOL } from '../../config.json';

export function LandingPage(props) {
    const login = () => window.location.href = `https://${HOST}/api/auth/discord`;
    return (
        <Button
            onClick={login}
            variantColor={"orange"}
            >Login</Button>
    )
}
