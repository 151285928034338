import { gql } from '@apollo/client';

export const updateGuildPrefixMutation = gql`
    mutation UpdateGuildPrefix($guildId: String!, $prefix: String!) {
        updateGuildPrefix(guildId: $guildId, prefix: $prefix) {
            prefix
            guildId
        }
    }
`;

export const updateGuildDefaultRoleMutation = gql`
    mutation UpdateGuildDefaultRole($guildId: String!, $defaultRole: String!) {
        updateGuildDefaultRole(guildId: $guildId, defaultRole: $defaultRole) {
            defaultRole
            guildId
        }
    }
`;

export const updateGuildModRoleMutation = gql`
    mutation UpdateGuildModRole($guildId: String!, $modRole: String!) {
        updateGuildModRole(guildId: $guildId, modRole: $modRole) {
            modRole
            guildId
        }
    }
`;

export const updateGuildAdminRoleMutation = gql`
    mutation UpdateGuildAdminRole($guildId: String!, $adminRole: String!) {
        updateGuildAdminRole(guildId: $guildId, adminRole: $adminRole) {
            adminRole
            guildId
        }
    }
`;

export const updateGuildWelcomeChannelMutation = gql`
    mutation UpdateGuildWelcomeChannel($guildId: String!, $welcomeChannel: String!) {
        updateGuildWelcomeChannel(guildId: $guildId, welcomeChannel: $welcomeChannel) {
            welcomeChannel
            guildId
        }
    }
`;

export const updateGuildWelcomeMsgMutation = gql`
    mutation UpdateGuildWelcomeMsg($guildId: String!, $welcomeMsg: String!) {
        updateGuildWelcomeMsg(guildId: $guildId, welcomeMsg: $welcomeMsg) {
            welcomeMsg
            guildId
        }
    }
`;

export const updateGuildNickMutation = gql`
    mutation UpdateGuildNick($guildId: String!, $guildNick: String!) {
        updateGuildNick(guildId: $guildId, guildNick: $guildNick) {
            guildNick
            guildId
        }
    }
`;
