import {Button, Input} from "@chakra-ui/core";
import {Formik} from "formik";
import React from "react";
import Select from "@chakra-ui/core/dist/Select";

export function DashboardMenu( {
    history,
    guildId,
    user,
    config,
    updatePrefix,
    updateDefaultRole,
    updateModRole,
    updateAdminRole,
    updateWelcomeChannel,
    updateWelcomeMsg,
    updateNick,
    roles,
    channels
 }) {
    const defaultRoleId = config.defaultRole ? config.defaultRole : '';
    const modRoleId = config.modRole ? config.modRole : '';
    const adminRoleId = config.adminRole ? config.adminRole : '';
    const welcomeChannelId = config.welcomeChannel ? config.welcomeChannel : '';

    return (
        <div>
            <Formik
                initialValues={{guildNick: config.guildNick}}
                onSubmit={({guildNick}) => {
                    //console.log(prefix);
                    updateNick(guildNick);
                }}>
                {
                    (props) => (
                        <form onSubmit={props.handleSubmit}>
                            <Input type="text" name={"guildNick"} onChange={props.handleChange} defaultValue={config.guildNick}/>
                            <Button type={"submit"} variantColor={"orange"} children={"Update Bot Nick"}/>
                        </form>
                    )
                }
            </Formik>
            <Formik
                initialValues={{prefix: config.prefix}}
                onSubmit={({prefix}) => {
                    //console.log(prefix);
                    updatePrefix(prefix);
                }}>
                {
                    (props) => (
                        <form onSubmit={props.handleSubmit}>
                            <Input type="text" name={"prefix"} onChange={props.handleChange} defaultValue={config.prefix}/>
                            <Button type={"submit"} variantColor={"orange"} children={"Update Prefix"}/>
                        </form>
                    )
                }
            </Formik>
            <Formik
                initialValues={{defaultRole: defaultRoleId}}
                onSubmit={({defaultRole}) => {
                    //console.log(defaultRole);
                    updateDefaultRole(defaultRole);
                }}>
                {
                    (props) => (
                        <form onSubmit={props.handleSubmit}>
                            <Select name={"defaultRole"} onChange={props.handleChange}>
                                {roles.map((role) => (
                                    <option value={role.id} selected={role.id === defaultRoleId}>{role.name}</option>
                                ))}
                            </Select>
                            <Button type={"submit"} variantColor={"orange"} children={"Update Default Role"}/>
                        </form>
                    )
                }
            </Formik>
            <Formik
                initialValues={{modRole: modRoleId}}
                onSubmit={({modRole}) => {
                    //console.log(modRole);
                    updateModRole(modRole);
                }}>
                {
                    (props) => (
                        <form onSubmit={props.handleSubmit}>
                            <Select name={"modRole"} onChange={props.handleChange}>
                                {roles.map((role) => (
                                    <option value={role.id} selected={role.id === modRoleId}>{role.name}</option>
                                ))}
                            </Select>
                            <Button type={"submit"} variantColor={"orange"} children={"Update Mod Role"}/>
                        </form>
                    )
                }
            </Formik>
            <Formik
                initialValues={{adminRole: adminRoleId}}
                onSubmit={({adminRole}) => {
                    //console.log(adminRole);
                    updateAdminRole(adminRole);
                }}>
                {
                    (props) => (
                        <form onSubmit={props.handleSubmit}>
                            <Select name={"adminRole"} onChange={props.handleChange}>
                                {roles.map((role) => (
                                    <option value={role.id} selected={role.id === adminRoleId}>{role.name}</option>
                                ))}
                            </Select>
                            <Button type={"submit"} variantColor={"orange"} children={"Update Admin Role"}/>
                        </form>
                    )
                }
            </Formik>
            <Formik
                initialValues={{welcomeChannel: welcomeChannelId}}
                onSubmit={({welcomeChannel}) => {
                    //console.log(adminRole);
                    updateWelcomeChannel(welcomeChannel);
                }}>
                {
                    (props) => (
                        <form onSubmit={props.handleSubmit}>
                            <Select name={"welcomeChannel"} onChange={props.handleChange}>
                                {channels.filter(c => c.type === 0 ).map((channel) => (
                                    <option value={channel.id} selected={channel.id === welcomeChannelId}>{channel.name}</option>
                                ))}
                            </Select>
                            <Button type={"submit"} variantColor={"orange"} children={"Update Welcome Channel"}/>
                        </form>
                    )
                }
            </Formik>
        </div>
    );
}
