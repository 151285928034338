import React from "react";
import { MenuComponent } from "../../components/MenuWrapper";
import {useQuery} from "@apollo/client";
import {menuPageQuery} from "../../graphql/queries";
import {Avatar} from "@chakra-ui/core";
import "./menu-page.css";

export function MenuPage( {
    history
} ) {

    const { loading, error, data } = useQuery(menuPageQuery);

    if(loading) return <span>Loading...</span>
    if(error) return <div><pre>Bad: {error.graphQLErrors.map(({ message }, i) => (
        <span key={i}>{message}</span>
    ))}
      </pre></div>
    const { getMutualGuilds, getBot } = data;
    return (
        <div>
            <h1>Menu Page</h1>
            <div>
                <Avatar
                    size={"sm"}
                    name={data.getUser.discordId}
                    src={`https://cdn.discordapp.com/avatars/${data.getUser.discordId}/${data.getUser.avatar}.png`}
                />
                <span>Welcome {data.getUser.discordTag}</span>
            </div>
            <hr/>
            <MenuComponent guilds={ getMutualGuilds } bot={ getBot }/>
        </div>
    )
}
