import React from "react";
import { DashboardMenu} from "../../components/DashboardMenu";
import {useMutation, useQuery} from "@apollo/client";
import {dashboardPageQuery} from "../../graphql/queries";
import {
    updateGuildAdminRoleMutation,
    updateGuildDefaultRoleMutation,
    updateGuildModRoleMutation,
    updateGuildPrefixMutation,
    updateGuildWelcomeChannelMutation,
    updateGuildWelcomeMsgMutation,
    updateGuildNickMutation
} from "../../graphql/mutations";
import Button from "@chakra-ui/core/dist/Button";

export function DashboardPage( {
    history,
    match
}) {

    const {loading, error, data} = useQuery(dashboardPageQuery, { variables: { guildId: match.params.id }});
    const [ updatePrefix ] = useMutation(updateGuildPrefixMutation);
    const [ updateDefaultRole ] = useMutation(updateGuildDefaultRoleMutation);
    const [ updateModRole ] = useMutation(updateGuildModRoleMutation);
    const [ updateAdminRole ] = useMutation(updateGuildAdminRoleMutation);
    const [ updateWelcomeChannel ] = useMutation(updateGuildWelcomeChannelMutation);
    const [ updateWelcomeMsg ] = useMutation(updateGuildWelcomeMsgMutation);
    const [ updateNick ] = useMutation(updateGuildNickMutation);

    const updateGuildPrefixParent = async (prefix) => {
       try{
           const response = await updatePrefix({
               variables: {
                   guildId: match.params.id,
                   prefix
               }
           });
           console.log(response);
       } catch (err) {
           console.log(err);
       }
    };

    const updateDefaultRoleParent = async (roleId) => {
        try{
            const response = await updateDefaultRole({
                variables: {
                    guildId: match.params.id,
                    defaultRole: roleId
                }
            });
            console.log(response);
        } catch (err) {
            console.log(err);
        }
    };

    const updateModRoleParent = async (roleId) => {
        try{
            const response = await updateModRole({
                variables: {
                    guildId: match.params.id,
                    modRole: roleId
                }
            });
            console.log(response);
        } catch (err) {
            console.log(err);
        }
    };

    const updateAdminRoleParent = async (roleId) => {
        try{
            const response = await updateAdminRole({
                variables: {
                    guildId: match.params.id,
                    adminRole: roleId
                }
            });
            console.log(response);
        } catch (err) {
            console.log(err);
        }
    };

    const updateWelcomeChannelParent = async (welcomeChannel) => {
        try{
            const response = await updateWelcomeChannel({
                variables: {
                    guildId: match.params.id,
                    welcomeChannel
                }
            });
            console.log(response);
        } catch (err) {
            console.log(err);
        }
    };

    const updateWelcomeMsgParent = async (welcomeMsg) => {
        try {
            const response = await updateWelcomeMsg( {
                variables: {
                    guildId: match.params.id,
                    welcomeMsg
                }
            });
            console.log(response);
        } catch (err) {
            console.log(err);
        }
    };

    const updateNickParent = async (guildNick) => {
        try {
            const response = await updateNick( {
                variables: {
                    guildId: match.params.id,
                    guildNick
                }
            });
            console.log(response);
        } catch (err) {
            console.log(err);
        }
    };

    if (!loading && !error) {

        const {
            getGuildConfig,
            getGuildRoles,
            getUser,
            getGuildChannels
        } = data;

        return (
            <div>
                <h1>Dashboard Page</h1>
                <DashboardMenu
                    user={getUser}
                    config={getGuildConfig}
                    updatePrefix={updateGuildPrefixParent}
                    updateDefaultRole={updateDefaultRoleParent}
                    updateModRole={updateModRoleParent}
                    updateAdminRole={updateAdminRoleParent}
                    updateWelcomeChannel={updateWelcomeChannelParent}
                    updateWelcomeMsg={updateWelcomeMsgParent}
                    updateNick={updateNickParent}
                    roles={getGuildRoles}
                    channels={getGuildChannels}
                />
                <br/>
                <Button leftIcon="arrow-back" variantColor="orange" variant="solid" onClick={() => history.goBack()}>
                    Back
                </Button>
            </div>
        )
    } return <h1>Loading....</h1>
}

